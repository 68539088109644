/* eslint-disable jsx-a11y/anchor-is-valid */
/* tslint:disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AccountInfo } from '@azure/msal-browser';
import styles from './Header.scss';

import IconBars from '../../assets/icons/bars.svg';
import IconCross from '../../assets/icons/cross-icon.svg';

type IProp = {
  centered?: boolean;
  applicationName: string;
  activeUser: AccountInfo | undefined;
  logoImage: string;
  accountImage: string;
  authInProgress: boolean;
  isAuthenticated: boolean;
  showTestBanner?: boolean;
  hamburgerMenu?: React.ReactElement;
  login?: () => Promise<void>;
  logout?: () => void;
  loginText?: string;
  logoutText?: string;
};

type IUserIcon = {
  activeUser: AccountInfo | undefined;
  accountImage: string;
};

function UserIcon({ activeUser, accountImage }: IUserIcon) {
  // @ts-ignore
  if (activeUser?.idTokenClaims?.user_type === 'Internal') {
    return <div className={styles['mdir-user']} />;
  }
  // @ts-ignore
  if (activeUser?.idTokenClaims?.idp_code === 'IdPorten') {
    return <div className={styles['idporten-user']} />;
  }
  // @ts-ignore
  if (activeUser?.idTokenClaims?.idp_code === 'Statsforvalteren') {
    return <div className={styles['statsforvalteren-user']} />;
  }
  return <img className={styles['user-image']} src={accountImage} alt='' />;
}
export function Header({
  centered,
  applicationName,
  activeUser,
  logoImage,
  accountImage,
  authInProgress,
  isAuthenticated,
  hamburgerMenu,
  showTestBanner,
  login,
  logout,
  loginText,
  logoutText
}: IProp) {
  const handleUserClick = () => {
    if (isAuthenticated && logout) {
      logout();
    }
    if (!isAuthenticated && login) {
      login();
    }
  };

  const handleLogoutClick = () => {
    if (logout) {
      logout();
    }
  };

  const handleLoginClick = () => {
    if (login) {
      login();
    }
  };

  const showUserInfo =
    !authInProgress && (isAuthenticated || (!isAuthenticated && login));

  const hasLogin = !isAuthenticated && login;
  const hasLogout = isAuthenticated;

  const containerClass = centered
    ? styles['header-container-centered']
    : hamburgerMenu
    ? styles['header-container-max-hamburger']
    : styles['header-container-max'];

  return (
    <div className={styles.header}>
      {hamburgerMenu && <HamburgerMenu hamburgerMenu={hamburgerMenu} />}

      <div className={`${styles['header-container']} ${containerClass}`}>
        <Link to='/'>
          <div className={styles['logo-group']}>
            <div className={styles['logo-container']}>
              <img src={logoImage} className={styles['logo-image']} alt='' />
              {showTestBanner && (
                <div className={styles['logo-banner']}>TEST</div>
              )}
            </div>

            <div className={styles['logo-divider']} />
            <div className={styles['sitename-text']}>{applicationName}</div>
          </div>
        </Link>

        {showUserInfo && (
          <div className={styles['user-group']}>
            <div
              role='button'
              tabIndex={0}
              className={styles['user-image-container']}
              onKeyPress={handleUserClick}
              onClick={handleUserClick}
            >
              <UserIcon activeUser={activeUser} accountImage={accountImage} />
            </div>
            <div className={styles['user-text']}>
              {hasLogout && (
                <React.Fragment>
                  <div>
                    <span className={styles['user-name']}>
                      {/* {JSON.stringify(activeUser?.idTokenClaims)} */}
                      {activeUser?.name}
                    </span>
                    <br />
                    <a
                      id='btnLogout'
                      role='button'
                      tabIndex={0}
                      className={styles['user-action']}
                      onKeyPress={handleLogoutClick}
                      onClick={handleLogoutClick}
                    >
                      {logoutText ?? 'Logg ut'}
                    </a>
                  </div>
                </React.Fragment>
              )}
              {hasLogin && (
                <a
                  id='btnLogin'
                  role='button'
                  tabIndex={0}
                  className={styles['user-action']}
                  onKeyPress={handleLoginClick}
                  onClick={handleLoginClick}
                >
                  {loginText ?? 'Logg inn'}
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

type IHamburgerMenu = {
  hamburgerMenu: React.ReactElement;
};

function HamburgerMenu({ hamburgerMenu }: IHamburgerMenu) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div
      role='button'
      tabIndex={0}
      className={`${styles.hamburger} d-block d-md-none`}
      aria-label='Toggle menu'
      onClick={toggle}
      onKeyPress={toggle}
    >
      {isOpen ? (
        <IconCross className={styles.icon} />
      ) : (
        <IconBars className={styles.icon} />
      )}

      {isOpen && hamburgerMenu}
    </div>
  );
}
