import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useBoundForm, Button, LoadingSpinner } from 'common.ui';
import {
  ApplicationResponse,
  GenericValidationError,
  OrganizationGroupResponse,
  OrganizationGroupType,
  PermissionCreateRequest,
  PermissionUpdateRequest,
  UserAuthenticationConstraint,
  UserType,
  AdgangAccessControl,
  ResourceFilter,
  AccessRequestApprovalType
} from 'api/adgang/models';
import { Container, Row, Col } from 'react-bootstrap';
import { useApplications, useOrganizationGroups } from 'api/hooks';
import { useUserAuthenticationConstraints } from 'api/hooks/useUserAuthenticationConstraint';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'RoutePaths';
import { useAccessControlList } from 'hooks/access/useAccessControlList';
import { RadioOption } from 'common.ui/dist/components/formElements/radioGroup/RadioGroup';

type PermissionsFormValue = PermissionCreateRequest | PermissionUpdateRequest;

type PermissionsFormProps = {
  initialValue?: PermissionsFormValue;
  isUpdate?: boolean;
  onSave: (model: PermissionsFormValue) => Promise<void>;
  onSaveLabel: string;
};

type Permission = {
  name: string;
  shortName: string;
  description: string;
  applicationId: string;
  tags: string;
  userType: string;
  canBeRequestedByUsers: string;
  organizationGroupId: string;
  userAuthenticationConstraint?: UserAuthenticationConstraint;
  requireUserOrganizationInMinSide: string;
  organizationGroupType?: OrganizationGroupType;
  accessRequestApprovalType: AccessRequestApprovalType;
  requiresMfa: string;
  allowEndUserCreation: string;
};

const yesNoOptions: RadioOption[] = [
  { id: 'yes', text: 'Ja' },
  { id: 'no', text: 'Nei' }
];

function PermissionsForm({
  initialValue,
  isUpdate,
  onSave,
  onSaveLabel
}: PermissionsFormProps): React.ReactElement<PermissionsFormProps> {
  const [, hasAccess] = useAccessControlList();
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [userAuthenticationConstraints] = useUserAuthenticationConstraints(false, true);
  const [requireUserAuthConstraint, setRequireUserAuthConstraint] = useState(false);
  const [applicationOptions, applications] = useApplications(
    undefined,
    ResourceFilter.Permission,
    !hasAccess(AdgangAccessControl.PermissionsViewShowNoAppFilter)
  );
  const [organizationGroupOptions, organizationGroups] = useOrganizationGroups();
  const [selectedApplication, setSelectedApplication] = useState<ApplicationResponse | undefined>(undefined);
  const [, setSelectedOrganizationGroup] = useState<OrganizationGroupResponse | undefined>(undefined);
  const history = useHistory();

  const [formAccessRequestApprovalTypeSeq, setFormAccessRequestApprovalTypeSeq] = useState(0);

  const [values, setValues] = useState<Permission>({
    name: initialValue?.name || '',
    shortName: initialValue?.shortName || '',
    description: initialValue?.description || '',
    applicationId: initialValue?.applicationId || 'global',
    organizationGroupId: `${initialValue?.organizationGroupId}`,
    tags: initialValue?.tags || '',
    userType: initialValue?.userType || UserType.Internal,
    canBeRequestedByUsers: initialValue?.canBeRequestedByUsers ? 'yes' : 'no',
    userAuthenticationConstraint: initialValue?.userAuthenticationConstraint,
    requireUserOrganizationInMinSide: initialValue?.requireUserOrganizationInMinSide ? 'yes' : 'no',
    // organizationGroupType: model.organizationGroupType
    //   ? (model.organizationGroupType as OrganizationGroupType)
    //   : undefined,
    accessRequestApprovalType:
      initialValue?.accessRequestApprovalType || AccessRequestApprovalType.LocalAdministrator,
    requiresMfa: initialValue?.requiresMfa ? 'yes' : 'no',
    allowEndUserCreation: initialValue?.allowEndUserCreation ? 'yes' : 'no'
  });

  const isReadOnly = !hasAccess(AdgangAccessControl.PermissionEdit);
  const isAllowedCreationExternalUsers = hasAccess(AdgangAccessControl.AllowCreationExternalUsers);

  const accessRequestApprovalTypeOptions = useMemo(() => {
    const options: RadioOption[] = [
      {
        id: AccessRequestApprovalType.NoApproval,
        text: 'Ingen godkjenning nødvendig'
      },
      {
        id: AccessRequestApprovalType.LocalAdministrator,
        text: 'Lokal administrator (Applikasjonsansvarlig)'
      }
    ];

    if (values.userType === UserType.External) {
      options.push({ id: AccessRequestApprovalType.ExternalAdministrator, text: 'Ekstern godkjenner' });
    }

    if (isAllowedCreationExternalUsers && values.userType === UserType.External) {
      options.push({
        id: AccessRequestApprovalType.ExternalApplicationAdministrator,
        text: 'Godkjenning skjer ved brukeropprettelse'
      });
    }

    return options;
  }, [isAllowedCreationExternalUsers, values.userType]);

  const OnFormSubmit = useCallback(
    async (submitModel: Permission) => {
      const model = { ...submitModel };
      setValues(model);
      try {
        await onSave({
          name: model.name,
          shortName: model.shortName,
          description: model.description,
          applicationId: model.applicationId !== 'global' ? model.applicationId : undefined,
          organizationGroupId: model.organizationGroupId !== '' ? +model.organizationGroupId : undefined,
          tags: model.tags,
          userType: model.userType ? (model.userType as UserType) : undefined,
          canBeRequestedByUsers: model.canBeRequestedByUsers === 'yes',
          userAuthenticationConstraint: model.userAuthenticationConstraint
            ? (model.userAuthenticationConstraint as UserAuthenticationConstraint)
            : undefined,
          requireUserOrganizationInMinSide:
            model.userType === UserType.Internal
              ? false
              : (model.organizationGroupId !== '' ? +model.organizationGroupId : undefined) !== undefined,
          // organizationGroupType: model.organizationGroupType
          //   ? (model.organizationGroupType as OrganizationGroupType)
          //   : undefined,
          accessRequestApprovalType: model.accessRequestApprovalType,
          requiresMfa: model.requiresMfa === 'yes',
          allowEndUserCreation: model.allowEndUserCreation === 'yes'
        });
        setApiErrors(undefined);
        history.push(RoutePaths.permissions);
      } catch (e) {
        if (e instanceof Response) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
    },
    [history, onSave]
  );

  const { form, FormContainer, RadioGroup, Dropdown, Input, DisplayErrors, TextArea } = useBoundForm<
    Permission
  >({
    onSubmit: async (e) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  useEffect(() => {
    setSelectedApplication(applications?.find((a) => a.id === values?.applicationId));
  }, [applications, values]);

  useEffect(() => {
    setSelectedOrganizationGroup(
      organizationGroups?.find((org) => org.organizationGroupId === +values?.organizationGroupId)
    );
  }, [organizationGroups, values]);

  useEffect(() => {
    const required =
      values.applicationId === 'global' ||
      (selectedApplication &&
        selectedApplication?.userAuthenticationConstraint ===
          UserAuthenticationConstraint.RequiresChildConfiguration);
    setRequireUserAuthConstraint(required || false);
  }, [selectedApplication, values]);

  if (!applications || !applicationOptions || !organizationGroupOptions) return <LoadingSpinner />;

  const setUserType = (userType: UserType) => {
    if (
      values.accessRequestApprovalType === AccessRequestApprovalType.ExternalAdministrator &&
      userType === UserType.Internal
    ) {
      setValues({
        ...values,
        userType,
        accessRequestApprovalType: AccessRequestApprovalType.LocalAdministrator,
        requireUserOrganizationInMinSide: 'no'
      });
      setFormAccessRequestApprovalTypeSeq(formAccessRequestApprovalTypeSeq + 1);
    } else {
      setValues({ ...values, userType });
    }
  };

  return (
    <Container fluid>
      <FormContainer form={form}>
        <Row>
          <Col sm={12} lg={6}>
            <DisplayErrors form={form} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} lg={4}>
            <Input readonly={isUpdate} form={form} name='shortName' label='Kortnavn' placeholder='Kortnavn' />

            <Dropdown
              form={form}
              name='applicationId'
              label='Applikasjon'
              readonly={(isUpdate && !hasAccess(AdgangAccessControl.RoleEditChangeApplication)) || isReadOnly}
              options={applicationOptions}
              onChange={(e) => {
                const val = e.target.value as string;
                setValues({ ...values, applicationId: val });
              }}
            />

            <RadioGroup
              form={form}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeUserType)}
              name='userType'
              label='Hvilken brukergruppe er tilgangen ment for?'
              options={[
                { id: UserType.Internal, text: 'Interne ansatte' },
                { id: UserType.External, text: 'Eksterne brukere' }
              ]}
              onChange={(e) => {
                setUserType(e as UserType);
              }}
            />

            {values.userType === UserType.External && (
              <Dropdown
                form={form}
                name='organizationGroupId'
                label='Forbeholdt eksterne brukere fra organisasjonsgruppe'
                placeholder=''
                options={organizationGroupOptions}
                onChange={(e) => {
                  const val = e.target.value as string;
                  setValues({ ...values, organizationGroupId: val });
                }}
              />
            )}

            <RadioGroup
              form={form}
              key={formAccessRequestApprovalTypeSeq}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeAccessRequestApprovalType)}
              name='accessRequestApprovalType'
              label='Skal tilgangen godkjennes av lokal administrator?'
              options={accessRequestApprovalTypeOptions}
              onChange={(e) => {
                setValues({ ...values, accessRequestApprovalType: e as AccessRequestApprovalType });
              }}
              layout='vertical'
            />

            <TextArea
              form={form}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeDescription)}
              name='description'
              label='Beskrivelse til bruk i MinSide'
              placeholder='Beskrivelse'
            />
          </Col>
          <Col sm={6} lg={4}>
            <Input
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeName)}
              form={form}
              name='name'
              label='Tilgangsnavn'
              placeholder='Tilgangsnavn'
            />

            <RadioGroup
              form={form}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeCanBeRequestedByUsers)}
              name='canBeRequestedByUsers'
              label='Er tilgangen søkbar i MinSide?'
              options={yesNoOptions}
            />

            {isAllowedCreationExternalUsers && values.userType === UserType.External && (
              <RadioGroup
                form={form}
                name='allowEndUserCreation'
                label='Tillat opprettelse av sluttbrukere'
                options={yesNoOptions}
              />
            )}

            <RadioGroup
              form={form}
              name='requiresMfa'
              label='Krever tilgangen multi-faktor autentisering for eksterne brukere?'
              options={yesNoOptions}
            />

            {requireUserAuthConstraint && (
              <Dropdown
                readonly={
                  isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeUserAuthenticationConstraint)
                }
                form={form}
                name='userAuthenticationConstraint'
                label='Type brukerautentisering'
                options={userAuthenticationConstraints}
              />
            )}

            <Dropdown readonly form={form} name='varighet' label='Varighet for rettighet' options={[]} />

            <TextArea
              form={form}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeTags)}
              name='tags'
              label='Stikkord til bruk i MinSide'
              placeholder='Legg til stikkord'
            />
          </Col>
        </Row>

        <Row>
          <Col></Col>
          <Col sm={12} lg={4}>
            <div className='float-right'>
              <Button disabled={isReadOnly} type='submit' text={onSaveLabel} />
              <Button
                disabled={isReadOnly}
                type='reset'
                text='Avbryt'
                styleType='light'
                onClick={() => history.push(RoutePaths.roles)}
              />
            </div>
          </Col>
        </Row>
      </FormContainer>
    </Container>
  );
}

export default PermissionsForm;
